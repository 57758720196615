// src/api/menuApi.ts
import axios from 'axios';
// Retrieves the profile data from the server
export const GetMenuItems = async ({ mock, }) => {
    if (mock) {
        return getMenuMock();
    }
    const response = await axios.get('/api/menu');
    return response.data;
};
// Your getMenuStatic function
const getMenuStatic = () => {
    const menu = [
        { id: '1', text: 'Dashboard', url: '#' },
        { id: '2', text: 'Bookings', url: '#' },
        { id: '3', text: 'Subscriptions', url: '#' },
        { id: '4', text: 'Profile', url: '#' },
        { id: '5', text: 'Authorized Users', url: '#' },
        { id: '6', text: 'Notification Preferences', url: '#' },
    ];
    return menu;
};
/* this will take the above mock data and return it as menuitemmodel for syncfusion */
const getMenuMock = async () => {
    const menu = getMenuStatic();
    const response = { data: menu };
    return response;
};
export default GetMenuItems;
