// Enum defining different authentication action types
export enum AuthActionTypes {
    Login = 'Login',
    Logout = 'Logout',
    ResetPassword = 'ResetPassword',
}

// Enum defining different authentication action statuses
export enum AuthActionStatuses {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
}
