import React from 'react';
import {
    ItemDirective,
    ItemsDirective,
    MenuComponent,
    MenuItemModel,
    ToolbarComponent,
} from '@syncfusion/ej2-react-navigations';
import AuthButton from '../AuthButton';
import { IconBBall_1, LogoSeaiSvg } from '@seai/icons';
import { RootState, useAppDispatch, fetchMenu } from '@seai/state';
import { useSelector } from 'react-redux';

export const AppMenu = () => {
    const menu = useSelector((state: RootState) => state.menu);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (menu.status === 'idle')
            // && menu.token !== null && menu.items.length === 0)
            dispatch(fetchMenu());
    }, [dispatch, menu.items, menu.status, menu.token, menu.error]);

    const logoTemplate = () => {
        return (
            <div>
                <LogoSeaiSvg width={25} />
            </div>
        );
    };
    const menuTemplate = () => {
        return (
            <div>
                <MenuComponent items={menu.items} />
            </div>
        );
    };
    const searchTemplate = () => {
        return (
            <div className="e-input-group">
                <input className="e-input" type="text" placeholder="Search" />
                <span className="e-input-group-icon em-icons e-search"></span>
            </div>
        );
    };

    return (
        <>
            <ToolbarComponent id="toolbar">
                <ItemsDirective>
                    <ItemDirective
                        cssClass="bg-red"
                        template={logoTemplate}
                        align="Left"
                    />
                    <ItemDirective template={menuTemplate} align="Left" />
                    <ItemDirective template={searchTemplate} align="Right" />
                    <ItemDirective template={AuthButton} align="Right" />
                </ItemsDirective>
            </ToolbarComponent>
        </>
    );
};
