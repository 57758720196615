import { AccountInfo } from '@azure/msal-browser';
import { TokenClaims } from '@azure/msal-common';

/* 
    export type AccountInfo = {
        homeAccountId: string;
        environment: string;
        tenantId: string;
        username: string;
        localAccountId: string;
        name?: string;
        idToken?: string;
        idTokenClaims?: TokenClaims & {
            [key: string]:
                | string
                | number
                | string[]
                | object
                | undefined
                | unknown;
        };
        nativeAccountId?: string;
        authorityType?: string;
        tenantProfiles?: Map<string, TenantProfile>;
    };
 */

/*
 * This exported type removes the non serializable properties from the AccountInfo type.
 * as of 6/29/24
 */

export type IdentityModel = Omit<AccountInfo, 'tenantProfiles'> & {
    idTokenClaims?: TokenClaims;
};

/**
 * Encapsulation of the identity of the user.
 * https://adrianhall.github.io/javascript/react/2020/03/06/msal-react-redux/
 */
export class Identity {
    private account: IdentityModel;

    constructor(account: AccountInfo) {
        this.account = account as IdentityModel;
    }

    get userId() {
        return this.account.localAccountId;
    }

    get emailAddress() {
        return this.account.username;
    }

    get name() {
        return this.account.name;
    }

    get idToken() {
        return this.account.idToken;
    }

    get claims() {
        return this.account.idTokenClaims;
    }
}
export default Identity;
