import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import profileReducer from './profileSlice';
import authReducer from './authSlice';
import errorReducer from './errorSlice';
import menuReducer from './menuSlice';

import loggerMiddleware from './loggerMiddleware';


// This file defines the Redux store for the application.

// Configure the Redux store with the profile reducer
const store = configureStore({
    reducer: {
        auth: authReducer, // Updated to match the slice name
        error: errorReducer, // Added to the store
        profile: profileReducer,
        menu: menuReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(loggerMiddleware),
});

// Define the types for the Redux store state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Export the store
export default store;
