import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the shape of the profile state
export interface IProfileState {
    name: string;
    email: string;
    phone?: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    emergencyContacts: { name: string; phone: string }[];
    authorizedUsers: IAuthorizedUser[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

// Define the shape of an authorized user
export interface IAuthorizedUser {
    name: string;
    age: number;
    email: string;
    phone?: string;
}

// Define the initial state of the profile slice
const initialState: IProfileState = {
    name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    emergencyContacts: [],
    authorizedUsers: [],
    status: 'idle',
};

// Create an async thunk to fetch the profile data from the API
export const fetchProfile = createAsyncThunk(
    'profile/fetchProfile',
    async () => {
        const response = await axios.get('/api/profile');
        return response.data;
    },
);

// Create the profile slice
const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        // Define the updateProfile reducer
        updateProfile(state, action) {
            return { ...state, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle the pending state of the fetchProfile async thunk
            .addCase(fetchProfile.pending, (state) => {
                state.status = 'loading';
            })
            // Handle the fulfilled state of the fetchProfile async thunk
            .addCase(fetchProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                Object.assign(state, action.payload);
            })
            // Handle the rejected state of the fetchProfile async thunk
            .addCase(fetchProfile.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

// Export the updateProfile action
export const { updateProfile } = profileSlice.actions;

// Export the profile reducer
export default profileSlice.reducer;
