/**
 * @file src/hooks/useAuth.ts
 * @version 0.1.1
 * @date 2024-06-08
 * @summary Authentication logic and components.
 * @contact Geoff DeFilippi, geoff@sportsevents.ai
 * @github SportsEventsAI/dev-identity
 * @description This file contains authentication-related logic and components to manage user authentication.
 * @notes This is more like the auth service.  we will consider it an auth service wrapper.
 * Each one of these is expected to dispatch state to the store using some action.
 * TODO: Add caching and error handling.
 * @reference
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { useMsal } from '@azure/msal-react';
// Local Modules
import { useAppDispatch, updateAuthState, AuthActionStatuses, AuthActionTypes, Identity, networkError, networkStart, networkStop, } from '@seai/state';
import { config, SeaiB2CPolicyTypes } from '@seai/config';
import { logClassCreation, logClassClosure, logMethodCall, logMethodResult, logMethodError, } from '@seai/logging';
let AuthServiceClass = class AuthServiceClass {
    instance = useMsal().instance; // Access the MSAL instance
    dispatch = useAppDispatch(); // Access the Redux dispatch function
    // Helper function to handle the different auth actions
    async handleAuthAction(action, request) {
        let authResponse;
        try {
            switch (action) {
                case AuthActionTypes.Login:
                    this.dispatch(networkStart()); // Dispatch the network start action to the Redux store
                    authResponse = await this.instance.loginPopup(request); // Perform login using MSAL
                    const user = new Identity(authResponse.account);
                    this.dispatch(updateAuthState({
                        status: AuthActionStatuses.Success,
                        user: user,
                    })); // Dispatch the updated authentication state to the Redux store
                    this.dispatch(networkStop()); // Dispatch the network stop action to the Redux store
                    break;
                case AuthActionTypes.Logout:
                    await this.instance.logoutPopup(request); // Perform logout using MSAL
                    this.dispatch(updateAuthState({ status: AuthActionStatuses.Success })); // Dispatch the updated authentication state to the Redux store
                    break;
                case AuthActionTypes.ResetPassword:
                    authResponse = await this.instance.loginPopup(request); // Perform password reset using MSAL
                    this.dispatch(updateAuthState({ status: AuthActionStatuses.Success })); // Dispatch the updated authentication state to the Redux store
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            this.dispatch(networkStop());
            if (error instanceof Error) {
                this.dispatch(networkError(error.message)); // Assuming networkError expects a string message
            }
            else {
                console.error('Caught an unexpected error type:', error);
            }
        }
    }
    // Function to handle login action
    async handleLogin() {
        const loginRequest = {};
        await this.handleAuthAction(AuthActionTypes.Login, loginRequest); // Call the helper function to handle the login action
    }
    // Function to handle logout action
    async handleLogout() {
        const logoutRequest = {};
        await this.handleAuthAction(AuthActionTypes.Logout, logoutRequest); // Call the helper function to handle the logout action
    }
    // Function to handle password reset action
    async handleResetPassword() {
        const resetRequest = {
            authority: config.b2c.getPolicyAuthority(SeaiB2CPolicyTypes.ResetPassword), // Get the authority for password reset
        };
        await this.handleAuthAction(AuthActionTypes.ResetPassword, resetRequest); // Call the helper function to handle the password reset action
    }
};
__decorate([
    logMethodCall,
    logMethodResult,
    logMethodError,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", Promise)
], AuthServiceClass.prototype, "handleAuthAction", null);
__decorate([
    logMethodCall,
    logMethodResult,
    logMethodError,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AuthServiceClass.prototype, "handleLogin", null);
__decorate([
    logMethodCall,
    logMethodResult,
    logMethodError,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AuthServiceClass.prototype, "handleLogout", null);
__decorate([
    logMethodCall,
    logMethodResult,
    logMethodError,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AuthServiceClass.prototype, "handleResetPassword", null);
AuthServiceClass = __decorate([
    logClassCreation,
    logClassClosure
], AuthServiceClass);
// Custom hook for using authentication logic
export const AuthService = () => {
    const service = new AuthServiceClass(); // Create an instance of AuthServiceClass
    return {
        handleLogin: service.handleLogin.bind(service),
        handleLogout: service.handleLogout.bind(service),
        handleResetPassword: service.handleResetPassword.bind(service),
    };
};
export default AuthService; // Export the default hook
