/**
 * @file src/config/index.ts
 * @version 0.2.1
 * @date 2024-06/16
 * @brief Entry point for configuration-related exports.
 * @contact Geoff DeFilippi, geoff@sportsevents.ai
 * @github SportsEventsAI/dev-identity
 * @subdirectory react spa
 * @filename src/config/index.ts
 * @details This module exports the configuration singleton and utility functions.
 */
// Setup String Parser Ext
import './stringExtensions';
import { ConfigSingleton } from './ConfigSingleton';
// Allow both kinds of imports
// Prefer using the default import as this is the only thing this file does
export const config = ConfigSingleton.instance;
export default config;
export * from './configTypes';
