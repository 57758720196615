/**
 * @file src/redux/authActions.ts
 * @version 0.1.1
 * @date 2024-06-08
 * @summary Authactions
 * @contact geoff@sportsevents.ai
 * @github SportsEventsAI/dev-identity
 * @description This file contains the authactions related logic.
 * @author Geoffrey DeFilippi
 */
import { createAction } from '@reduxjs/toolkit';
import { AuthActionStatuses } from './types';
import Identity from './identity';

export interface AuthPayloadModel {
    status: AuthActionStatuses;
    user?: Identity | null;
    error?: string | null;
}

export const updateAuthState = createAction<AuthPayloadModel>(
    'auth/updateAuthState',
);

// Network actions
export const networkStart = createAction('network/start');
export const networkStop = createAction('network/stop');
export const networkError = createAction<string>('network/error');
