/**
 * @file src/redux/menuSlice.ts
 * @version 0.3.1
 * @date 2024-06-18
 * @contact geoff@sportsevents.ai
 * @details This file defines the Redux slice for menu management.
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MenuItemModel } from '@syncfusion/ej2-react-navigations';

import { GetMenuItems } from '@seai/api';

export interface IMenuState {
    token: string | null;
    items: MenuItemModel[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Initial state
const initialState: IMenuState = {
    token: null,
    items: [],
    status: 'idle',
    error: null,
};

// Thunk for fetching menu data
export const fetchMenu = createAsyncThunk('menu/fetchMenu', async () => {
    const response = await GetMenuItems({ mock: true });
    return response.data;
});

// Menu slice
const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenu.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMenu.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchMenu.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch menu';
            });
    },
});

export default menuSlice.reducer;
