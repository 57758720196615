/**
 * @file src/config/configTypes.ts
 * @version 0.2.1
 * @date 2024-06/16
 * @summary Type DeFinitions for this project.
 * @contact Geoff DeFilippi, geoff@sportsevents.ai
 * @github SportsEventsAI/dev-identity
 * @description This is the main file of types for this project.
 */
// Enum defining different B2C domain types
export var SeaiB2CDomainTypes;
(function (SeaiB2CDomainTypes) {
    SeaiB2CDomainTypes["Tenant"] = "tenant";
    SeaiB2CDomainTypes["Login"] = "login";
})(SeaiB2CDomainTypes || (SeaiB2CDomainTypes = {}));
// Enum defining different B2C policy types
export var SeaiB2CPolicyTypes;
(function (SeaiB2CPolicyTypes) {
    SeaiB2CPolicyTypes["SignUpSignIn"] = "signUpSignIn";
    SeaiB2CPolicyTypes["SignIn"] = "signIn";
    SeaiB2CPolicyTypes["ResetPassword"] = "resetPassword";
    SeaiB2CPolicyTypes["EditProfile"] = "editProfile";
    SeaiB2CPolicyTypes["SignUp"] = "signUp";
    SeaiB2CPolicyTypes["SignOut"] = "signOut";
})(SeaiB2CPolicyTypes || (SeaiB2CPolicyTypes = {}));
