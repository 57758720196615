/**
 * @file src/redux/authSlice.ts
 * @version 0.1.1
 * @date 2024-06-08
 * @summary Authslice
 * @contact geoff@sportsevents.ai
 * @github SportsEventsAI/dev-identity
 * @description This file contains the authslice related logic.
 * @author Geoffrey DeFilippi
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    updateAuthState,
    AuthPayloadModel,
    networkStart,
    networkStop,
    networkError,
} from './authActions';
import { AuthActionStatuses } from './types';
import Identity from './identity';

export interface AuthStateModel {
    isAuthenticated: boolean;
    user: Identity | null;
    networkRequests: number;
    error: string | null;
}

const initialState: AuthStateModel = {
    isAuthenticated: false,
    user: null,
    networkRequests: 0,
    error: null,
};

/**
 * Represents the authentication slice of the Redux store.
 */
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            updateAuthState,
            (state, action: PayloadAction<AuthPayloadModel>) => {
                if (action.payload.status === AuthActionStatuses.Success) {
                    state.isAuthenticated = true;
                    state.user = action.payload.user || null;
                    state.error = null;
                } else if (
                    action.payload.status === AuthActionStatuses.Failure
                ) {
                    const error =
                        action.payload.error || 'An unknown error occurred.';

                    state.isAuthenticated = false;
                    state.user = null;
                    state.error = error;
                    // rethrow error to allow the caller to handle it
                    //DEBUG ONLY
                    //throw new Error(error);
                }
            },
        );

        builder.addCase(networkStart, (state) => {
            state.networkRequests += 1;
        });

        builder.addCase(networkStop, (state) => {
            state.networkRequests -= 1;
        });

        builder.addCase(networkError, (state, action) => {
            state.error = action.payload;
        });
    },
});

export default authSlice.reducer;
