import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { registerLicense } from '@syncfusion/ej2-base';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { store } from '@seai/state'; // Import the Redux Store from the state package'

import config from '@seai/config'; // Import the configuration from the config package

import App from './pages/App'; // Import the App component from the pages package

import ErrorBoundary from './components/boundaries/ErrorBoundary';

import './index.css';

/* Register Syncfusin License */
const LicenseKey =
    import.meta.env.VITE_SYNCFUSION_KEY === ''
        ? 'Invalid'
        : import.meta.env.VITE_SYNCFUSION_KEY;
if (LicenseKey === 'Invalid') {
    console.error('Syncfusion License Key is not provided');
}

registerLicense(LicenseKey);

/* Do other early load stuff here
 * Please do no abuse this will slow the app down
 *
 */

/**
 * Setup the MSAL instance
 */
const msalConfig = config.b2c.getMsalConfig();
if (msalConfig === null || msalConfig === undefined) {
    throw new Error('Msal Configuration not found');
}
const msalInstance = new PublicClientApplication(msalConfig);

/* Render the App component
 *  into the div with id 'root' in the public/index.html file
 */
const rootElement = document.getElementById('root');
if (rootElement === null || rootElement === undefined) {
    throw new Error('Root element not found');
}
const root = ReactDOM.createRoot(rootElement);
root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </ErrorBoundary>
    </Provider>,
);
