import React from 'react';

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';

import { useSelector } from 'react-redux';

import { RootState } from '@seai/state';

import { AppMenu } from '../components/AppMenu/AppMenu';
import GroupEvent from '../components/GroupEvent/GroupEvent';

const App = () => {
    const user = useSelector((state: RootState) => state.auth.user);

    return (
        <div className="App">
            <AppMenu />
            <div className="content">{/* Other content goes here */}</div>
            <AuthenticatedTemplate>
                <>
                    <h1>Welcome, {user?.name}!</h1>
                    <GroupEvent />
                </>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h1> Please login to view the menu </h1>
            </UnauthenticatedTemplate>
        </div>
    );
};
export default App;
